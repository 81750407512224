<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title
          >Withdraw Details
            <v-btn rounded color="red" class="ml-4" dark @click="dialog = true"
            >Request Withdraw
            </v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="getWithdrawReq" :search="search">
            <template v-slot:[`item.name`]="{ item }">
              <!--              {{bankDetails}}-->
              {{ getRequestedBank(item.name) }}
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              Withdraw Request
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-select
                    :items="getBankName"
                    v-model="editedItem.bank_details_id"
                    item-text="name"
                    item-value="id"
                    label="Select Bank"
                    dense
                    outlined
                ></v-select>
                <v-text-field
                    outlined
                    v-model="editedItem.withdraw_amount"
                    :rules="amountValidation"
                    dense
                    min="1"
                    label="Amount"
                    type="number"
                ></v-text-field>

                <v-btn color="primary" @click="requestWithdraw()">Submit</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import moment from "moment";
import AvailableBalance from "../../mixins/AvailableBalance";

export default {
  mixins: [AvailableBalance],
  data() {
    return {
      dialog: false,
      valid: true,
      editedItem: {},
      search: "",
      headers: [
        {text: "S.N.", value: "sn"},
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "Amount", value: "withdraw_amount"},
        {text: "Status", value: "status"},
        {text: "Request Date", value: "created_at"},
      ],
      amountValidation: [
        v => !!v || 'This Field is required',
        v => (v && v <= this.availableBalance()) || 'You Dont Have Enough Balance',


      ],
    };
  },
  computed: {
    ...mapState({
      wallet: (state) => state.wallet,
      withdrawRequests: (state) => state.withdrawRequests,
      bankDetails: (state) => state.bankDetails,
    }),
    ...mapGetters({
      getWithdraw: 'getWithdraw'
    }),
    getBankName() {
      return this.bankDetails.map((item) => ({
        name: item.bank_name,
        id: item.id,
      }));
    },


    getWithdrawReq() {
      return this.getWithdraw.map((item, index) => ({
        sn: index + 1,
        withdraw_amount: item.withdraw_amount,
        name: item.bank_details_id,
        status: this.checkStatus(item.rejected,item.withdrawn),
        created_at: this.formatDate(item.created_at),

      }))
    }
  },
  methods: {
    ...mapActions(["storeWithdrawRequest"]),
    withdraw(item) {
      let result = item.filter((item) => item.withdrawn === 0)
      return Object.assign({}, result);
    },

    checkStatus(rejected, withdrawn) {
      if (rejected === 1)
        return "Rejected";
      if (withdrawn === 1)
        return "Withdrawn";
      else
        return "Requested"
    },

    requestWithdraw() {
      this.storeWithdrawRequest(this.editedItem)
      this.dialog = false
    },
    getRequestedBank(bankId) {
      return this.getBankName.filter((item) => item.id === bankId)[0].name;

    },

    formatDate(value) {
      return moment(value).format("MMMM DD YYYY")
    },
  }
};
</script>

<style></style>
